import React, { useRef, useEffect } from "react";
import Iframe from "react-iframe";
import { useState } from "react";
import CourseService from "../services/course.service";
import AuthService from "../services/auth.service";
import LoadingGif from "../../src/assets/images/Loading.gif";
import ReportService from "../services/report.service";

const DemoPlayer = ({
  aid,
  cid,
  getCourseActivities,
  getCourseCompSts,
  activitySts,
  attemptCount,
  attempt,
  fetchActivityResults,
  file_name,
  activity_type,
  comp_sts,
  activity_name,
  enrolid,
}) => {
  console.log("player enrollid", enrolid);
  const iframeRef = useRef(null);
  const [isFunctionRunning, setIsFunctionRunning] = useState(false);
  const videoRef = useRef(null);
  const [duration, setDuration] = useState(0);
  // const [currentTime, setCurrentTime] = useState(0);
  const [totalPlayTime, setTotalPlayTime] = useState(0);
  const [mediaStatus, setMediaStatus] = useState("");
  const [activityResult, setActivityResult] = useState({
    strscore_max: "",
    strscore_min: "",
    strscore_raw: "",
    strsession_time: "",
    strlesson_status: "",
    course_id: cid,
    activity_id: aid,
    activity_typeid: "",
    student_id: "",
    enrollid: enrolid,
  });

  const user = AuthService.getCurrentUser();

  const token = AuthService.getToken();

  const url =
    "https://aeonlms.aeonsoftware.net/cit/uploads/extracted/Cyber Security Awareness_Assessment/index_lms.html";


  const actor = JSON.stringify({"name": `${user.name}`,"account":[{"accountServiceHomePage":"https://aeonlms.aeonsoftware.net","accountName":"8NLK8B8O4G|amit.kadu@aeonsoftware.net"}],"objectType":"Agent"});

  const endpoint =
    `https://aeonlms.aeonsoftware.net/cit/api/&auth=Bearer ${token}`;

  const content_token =
    "0214b590-a1f9-40b0-8db5-a16d6db11eb9&externalRegistration=yC3vtgNyFMEDBIN5CbnXNXgtwYezUaXzkvJnj8y7dzGIXP9t8Hne9JrN1hw4YmvozoMhqiqJm1MoNNMtxoOGZm1vlVfC-ywigEmv-o1p0DX3gMuYoMIEWSk3iM0CrSY";

  const activity_id =
    "urn:articulate:storyline:5hiUTuKA08A&registration=0e8622b6-dd23-4ebc-a9b2-fed04ed14b1a&externalConfiguration=9nEmIdOfaopsxGaud88gS5T8uRtwavhMSZEft-0BffAII6sQ_1mJFUoJmIaK5uSOv9Nmg9vpQbF__bBd3BgC3m5bqcFHqMnH";

  const grouping = "urn:articulate:storyline:5hiUTuKA08A";

  const content_endpoint = "https://aeonlms.aeonsoftware.net/cit/";

  const width = "988";

  const height = "824";

  const left = 274;
  const top = 0;



  const isSafari = navigator.appVersion.indexOf("Safari") >= 0;
  const isChromeless = false;
  const isResizeable = false;
  const contentWidth = 1300;
  const contentHeight = 791;

  const [startPage, setStartPage] = useState(
    `${url}?actor=${actor}&endpoint=${endpoint}&content_token=${content_token}&activity_id=${activity_id}&grouping=${grouping}&content_endpoint=${content_endpoint}&width=${width}&height=${height}&left=${left}&top=${top}`
  );
  const browserSize = "optimal";

  const launchContent = () => {
    let width = window.screen.availWidth;
    let height = window.screen.availHeight;

    if (
      width > contentWidth &&
      height > contentHeight &&
      browserSize !== "fullscreen"
    ) {
      width = contentWidth;
      height = contentHeight;

      if (!isSafari && !isChromeless) {
        width += 17;
      }
    }

    let options = `width=${width},height=${height}`;
    options += isResizeable ? ",resizable=yes" : ",resizable=no";

    if (isChromeless) {
      options += ",status=0,toolbar=0,location=0,menubar=0,scrollbars=0";
    } else {
      options += ",status=1,toolbar=1,location=1,menubar=1,scrollbars=1";
    }

    if (isSafari) {
      const newWindow = window.open("", "_blank", options);
      newWindow.location = startPage;
      window.onfocus = function () {
        // console.log("Window is focused!");
        console.log("Please do not open new tab while running course.");
        newWindow.close();
      };
    } else {
      const newWindow = window.open(startPage, "_blank", options);
      const script = newWindow.document.createElement("script");
      script.innerHTML = `
      document.addEventListener('contextmenu', function (e) {
        e.preventDefault();
      });
    `;
      newWindow.document.head.appendChild(script);
    }
  };

  const xapiRun = (cid, aid, user, enrolid) => {
    let activityRes = {};

    setActivityResult((prevState) => ({
      ...prevState,
      enrollid: enrolid,
    }));

    const tspInit = (window, storage, prefix, callback) => {

      prefix = typeof prefix !== "undefined" ? prefix : "";
      callback = typeof callback !== "undefined" ? callback : console.log;

      window.xapiStatus = {
        lesson_status: "",
        score_raw: 0,
        score_max: 100,
        score_min: 0,
        session_time: 0,
        detailed_answers: {},
        lesson_location: "",
        student_id: user.user,
        course_id: "",
        enrollid: "",
      };

      window.API = {};

      const lrsEndpoint = "/api";
      const auth = `Bearer ${token} ` ;

      const xapiSendStatement = (statement) => {
        fetch(lrsEndpoint + "/xapitest", {
          method: "POST",
          headers: {
            Authorization: auth,
            "Content-Type": "application/json",
            "X-Experience-API-Version": "1.0.0",
          },
          body: JSON.stringify(statement),
        })
          .then((resp) => console.log("Statement sent"))
          .catch((err) => console.log(err));
      };

      // xAPI: Record a statement for initialized action

      window.API.LMSInitialize = function () {
        const initializedStatement = {
          statement_id: "",
          actor: {
            mbox: `mailto:amit.kadu@aeonsoftware.net`,
            name: user.name,
          },
          verb: {
            id: "http://adlnet.gov/expapi/verbs/initialized",
            display: { "en-US": "initialized" },
          },
          object: {
            id: `${url}`,
            definition: {
              name: { "en-US": "Course Initialization" },
            },
          },
          result: {
            score: {
              raw: window.xapiStatus.score_raw,
              max: window.xapiStatus.score_max,
              min: window.xapiStatus.score_min,
            },
          },
          context: null
        };
        console.log(
          "LMSInitialize (xAPI does not require explicit initialization)"
        );
        xapiSendStatement(initializedStatement);
      };

      window.API.LMSTerminate = function () {
        console.log("LMSTerminate");
        const terminatedStatement = {
          actor: {
            mbox: `mailto:amit.kadu@aeonsoftware.net`,
            name: user.name,
          },
          verb: {
            id: "http://adlnet.gov/expapi/verbs/terminated",
            display: { "en-US": "terminated" },
          },
          object: {
            id: `${url}`,
            definition: {
              name: { "en-US": "Course Terminated" },
            },
          },
        };
        xapiSendStatement(terminatedStatement);
      };

      window.API.LMSSetValue = function (varname, varvalue) {
        varname = prefix + varname;

        if (varname === prefix + "cmi.core.lesson_status")
          window.xapiStatus.lesson_status = varvalue;
        setActivityResult({
          ...activityResult,
          strlesson_status: window.xapiStatus.lesson_status,
        });

        if (varname === prefix + "cmi.core.score.raw")
          window.xapiStatus.score_raw = varvalue;
        setActivityResult({
          ...activityResult,
          strscore_raw: window.xapiStatus.score_raw,
        });

        if (varname === prefix + "cmi.core.session_time")
          window.xapiStatus.session_time = varvalue;
        setActivityResult({
          ...activityResult,
          strsession_time: window.xapiStatus.session_time,
        });

        // Record a statement for value changes
        const updatedStatement = {
          actor: {
            mbox: `mailto:amit.kadu@aeonsoftware.net`,
            name: user.name,
          },
          verb: {
            id: "http://adlnet.gov/expapi/verbs/interacted",
            display: { "en-US": "interacted" },
          },
          object: {
            id: `${startPage}`,
            definition: {
              name: { "en-US": `Updated: ${varname}` },
            },
          },
          result: {
            score: {
              raw: window.xapiStatus.score_raw,
              max: window.xapiStatus.score_max,
              min: window.xapiStatus.score_min,
            },
            duration: window.xapiStatus.session_time,
          },
        };
        xapiSendStatement(updatedStatement);

        storage.setItem(varname, varvalue);
      };

      window.API.LMSCommit = function () {
        callback(window.xapiStatus);
        return true;
      };

      window.API.LMSFinish = function () {
        console.log("LMSFinish");
        const finishStatement = {
          actor: {
            mbox: `mailto:amit.kadu@aeonsoftware.net`,
            name: user.name,
          },
          verb: {
            id: "http://adlnet.gov/expapi/verbs/completed",
            display: { "en-US": "completed" },
          },
          object: {
            id: `${url}`,
            definition: {
              name: { "en-US": "Course Finished" },
            },
          },
        };
        xapiSendStatement(finishStatement);

        uploadResult(activityRes);
      };

      window.API.LMSGetLastError = function () {};
      window.API.LMSGetErrorString = function () {};
      window.API.LMSGetDiagnostic = function () {};
    };

    const supports_html5_storage = () => {
      try {
        return "localStorage" in window && window["localStorage"] !== null;
      } catch (e) {
        return false;
      }
    };

    if (!supports_html5_storage()) {
      window.localStorage = {};
    }

    tspInit(
      window,
      window.localStorage,
      `CORS.1.ACT.2.UID.${user.user}.`,
      function (progress) {
        var progressString = JSON.stringify(progress);
        localStorage.setItem("std_progress", progressString);
        activityRes = {
          strscore_max: progress.score_max,
          strscore_min: progress.score_min,
          strscore_raw: progress.score_raw,
          strsession_time: progress.session_time,
          strlesson_status: progress.lesson_status,
          course_id: cid,
          activity_id: aid,
          student_id: user.user,
          activity_typeid: "1",
          enrollid: enrolid,
        };
      }
    );
  };

  const handlePlay = () => {
    const video = videoRef.current;
    if (video) {
      let durationInMinutes = Math.floor(video.duration / 60);
      // let durationInSeconds = durationInMinutes % 60;
      setDuration(Math.floor(video.duration));
      const intervalId = setInterval(() => {
        // const unit = "seconds";
        // setCurrentTime(Math.round(video.currentTime) + " " + unit);
        setTotalPlayTime((prevTotalPlayTime) => prevTotalPlayTime + 1);
      }, 1000);

      video.onpause = video.onended = () => {
        clearInterval(intervalId);
      };
    }
  };

  const handlePause = () => {
    uploadMediaRes();
  };

  const uploadResult = (data) => {
    const user = AuthService.getCurrentUser();

    console.log(data);

    const datanew = {
      enrollid: enrolid,
      course_id: cid,
      activity_id: aid,
      student_id: user.user,
    };

    const fetchResdata = {
      enrollid: enrolid,
      corsid: cid,
      acrto: aid,
      baps: user.user,
    };

    console.log("datanew", datanew);
    console.log("fetchResdata", fetchResdata);

    CourseService.uploadScromResult(data)
      .then((res) => {
        if (res.data !== false) {
          fetchActivityResults(datanew, fetchResdata);
          getCourseActivities();
          getCourseCompSts(user.user, cid);
        }
      })
      .catch((e) => console.log(e));
  };

  const uploadMediaRes = () => {
    const user = AuthService.getCurrentUser();

    const datanew = {
      enrollid: enrolid,
      course_id: cid,
      activity_id: aid,
      student_id: user.user,
    };

    const fetchResdata = {
      enrollid: enrolid,
      corsid: cid,
      acrto: aid,
      baps: user.user,
    };

    const mediadata = {
      strscore_max: "",
      strscore_min: "",
      strscore_raw: "",
      strsession_time: totalPlayTime,
      strlesson_status: mediaStatus,
      course_id: cid,
      activity_id: aid,
      activity_typeid: "2",
      duration: duration,
      student_id: user.user,
    };

    CourseService.uploadScromResult(mediadata)
      .then((res) => {
        if (res.data !== false) {
          fetchActivityResults(datanew, fetchResdata);
          getCourseActivities();
          getCourseCompSts(user.user, cid);
        }
      })
      .catch((e) => console.log(e));
  };

  const handleIframeLoad = () => {
    const user = AuthService.getCurrentUser();

    const datanew = {
      enrollid: enrolid,
      course_id: cid,
      activity_id: aid,
      student_id: user.user,
    };

    const fetchResdata = {
      enrollid: enrolid,
      corsid: cid,
      acrto: aid,
      baps: user.user,
    };

    const mediadata = {
      strscore_max: "",
      strscore_min: "",
      strscore_raw: "",
      strsession_time: "",
      strlesson_status: "completed",
      course_id: cid,
      activity_id: aid,
      activity_typeid: "2",
      duration: duration,
      student_id: user.user,
    };

    CourseService.uploadScromResult(mediadata)
      .then((res) => {
        if (res.data !== false) {
          setIsFunctionRunning(true);
          fetchActivityResults(datanew, fetchResdata);
          getCourseActivities();
          getCourseCompSts(user.user, cid);
        }
      })
      .catch((e) => {
        console.log(e);
        setIsFunctionRunning(false);
      });
  };

  useEffect(() => {
    const user = AuthService.getCurrentUser();
    setActivityResult({ ...activityResult, student_id: user.user });
    xapiRun(cid, aid, user, enrolid);

    if (totalPlayTime >= duration) {
      setMediaStatus("completed");
    } else {
      setMediaStatus("incomplete");
    }

    const disableScreenshot = (e) => {
      navigator.clipboard.writeText("");
      console.log("screenshot disabled");
    };

    document.addEventListener("keyup", disableScreenshot);

    return () => {
      document.removeEventListener("keyup", disableScreenshot);
    };
  }, [aid]);

  return (
    <>
      {/* <button
        onClick={() => {
          launchContent();
          setStartPage(
            "https://aeonlms.aeonsoftware.net/cit/uploads/extracted/MNN_xAPI/index_lms.html"
          );
        }}
        className="launch"
      >
        Launch
      </button> */}

      {/* <Iframe
        url={
          "https://aeonlms.aeonsoftware.net/cit/uploads/extracted/MNN_LRS_20092024/index_lms.html?endpoint=https://cloud.scorm.com/lrs/8NLK8B8O4G/&fetch=http://lms.example.com/tokenGen.htm?k=2390289x0"
        }
        id="pdfPlayer"
        width="640px"
        height="320px"
        display="block"
        position="relative"
        ref={iframeRef}
        className="mb-3"
      /> */}

      <button
        onClick={() => {
          launchContent();
          setStartPage(
            `${url}?actor=${actor}&endpoint=${endpoint}&content_token=${content_token}&activity_id=${activity_id}&grouping=${grouping}&content_endpoint=${content_endpoint}&width=${width}&height=${height}&left=${left}&top=${top}`
          );
        }}
        className="launch"
      >
        Launch Course
      </button>
    </>
  );
};
export default DemoPlayer;
