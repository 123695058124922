import React, { useState, useEffect, useRef } from "react";
import { Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { useNavigate } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";

import GoogleTranslate from "./GoogleTranslate";

import { setUserData } from "./actions/index";

// components
import Login from "./components/Login/Login";
import Forgot from "./components/Login/Forgot";
import ChangePassword from "./components/Login/ChangePassword";

import Sidebar from "./components/Sidebar/Sidebar";
import Topbar from "./components/Topbar/Topbar";
import SADashboard from "./components/SuperAdmin/Dashboard/SADashboard";

import SystemManagers from "./components/SuperAdmin/UserManagement/SystemManagers";
import PresetRoles from "./components/SuperAdmin/UserManagement/PresetRoles";
import AllLearners from "./components/SuperAdmin/UserManagement/AllLearners";
import Cohorts from "./components/SuperAdmin/UserManagement/Cohorts";
import AddUser from "./components/SuperAdmin/UserManagement/AddUser";
import EditLearner from "./components/SuperAdmin/UserManagement/EditLearner";
import EditSystemManagers from "./components/SuperAdmin/UserManagement/EditSystemManagers";
import ManageCohortParticipants from "./components/SuperAdmin/UserManagement/ManageCohortParticipants";
import AddCohort from "./components/SuperAdmin/UserManagement/AddCohort";
import EditCohort from "./components/SuperAdmin/UserManagement/EditCohort";

import ManageCourses from "./components/SuperAdmin/CourseManagement/ManageCourses";
import ManageParticipants from "./components/SuperAdmin/CourseManagement/ManageParticipants";
import ManagePathway from "./components/SuperAdmin/CourseManagement/ManagePathway";
import PathwayParticipants from "./components/SuperAdmin/CourseManagement/PathwayParticipants";
import CreatePathway from "./components/SuperAdmin/CourseManagement/CreatePathway";
import EditPathway from "./components/SuperAdmin/CourseManagement/EditPathway";
import EditCourse from "./components/SuperAdmin/CourseManagement/EditCourse";

import ManageOrganisation from "./components/SuperAdmin/ManageOrganisation/ManageOrganisation";
import AddSubOrg from "./components/SuperAdmin/ManageOrganisation/AddSubOrg";
import EditOrg from "./components/SuperAdmin/ManageOrganisation/EditOrg";
import EditSubOrg from "./components/SuperAdmin/ManageOrganisation/EditSubOrg";
import CertificateConfigure from "./components/SuperAdmin/Settings/CertificateConfigure";
import ServerSettings from "./components/SuperAdmin/Settings/ServerSettings";
import ThemeSettings from "./components/SuperAdmin/Settings/ThemeSettings";
import SetCertificateTemplate from "./components/SuperAdmin/Settings/SetCertificateTemplate";
import ErrorRequest from "./components/SuperAdmin/HelpNSupport/ErrorRequest";
import Steps from "./components/SuperAdmin/Onboarding/Steps";
import CreateCourse from "./components/SuperAdmin/CourseManagement/CreateCourse";
import CourseDraft from "./components/SuperAdmin/CourseManagement/CourseDraft";
// services
import AuthService from "./services/auth.service";
import EventBus from "./common/EventBus";
import UserService from "./services/user.service";

import SettingsService from "./services/settings.service";

import CertificatePreview from "./components/SuperAdmin/Settings/CertificatePreview";

import LearnerDashboard from "./components/Learner/LearnerDashboard";
import Learn from "./components/Learner/Learn";
import Track from "./components/Learner/Track";
import Communication from "./components/Learner/Communication";

import ViewCourse from "./common/ViewCourse";
import ViewActivity from "./common/ViewActivity";
import Player from "./common/Player";
import Certificate from "./components/Learner/Certificate";

// admin

import AdminOnboard from "./components/Admin/AdminOnboard";
import AdminDashbaord from "./components/Admin/AdminDashbaord";
import RaiseRequest from "./common/RaiseRequest";
import Achievements from "./components/Learner/Achievements";

import Reports from "./components/Reports/Reports";
import ViewReport from "./components/Reports/ViewReport";
import AddReport from "./components/Reports/AddReport";
import EditReport from "./components/Reports/EditReport";

import ActivityLog from "./components/Reports/ActivityLog";
import ActivityLogNew from "./components/Reports/ActivityLogNew";
import FeedbackReport from "./components/Reports/FeedbackReport";
import CourseEnrollmentReport from "./components/Reports/CourseEnrollmentReport";
import UserEnrollmentReport from "./components/Reports/UserEnrollmentReport";
import ActivityResultReport from "./components/Reports/ActivityResultReport";
import CertificateReports from "./components/Reports/CertificateReports";
import PaymentHistoryReport from "./components/Reports/PaymentHistoryReport";
import CourseStatusReport from "./components/Reports/CourseStatusReport";

import LearnerOnboarding from "./components/Learner/Onboarding/LearnerOnboarding";

import Event from "./common/Event";
import PermissionService from "./services/permission.service";

import EditProfile from "./components/EditProfile";

// import PaymentPage from "./components/PaymentPage";
import CourseCatalog from "./components/Learner/CourseCatalog";
// import ViewStudentCourses from "./components/SuperAdmin/UserManagement/ViewStudentCourses";
import CatalogCourseOverview from "./components/Learner/CatalogCourseOverview";
import CourseCart from "./components/Learner/CourseCart";

import PaymentSuccess from "./components/Learner/PaymentSuccess";
import PaymentFailure from "./components/Learner/PaymentFailure";

import CartService from "./services/cart.service";

import PageNotFound from "./common/PageNotFound";

// guest login
import GuestCourseCatalog from "./components/GuestLogin/GuestCourseCatalog";
import GuestCatalogCourseOverview from "./components/GuestLogin/GuestCatalogCourseOverview";

import AllLearnersProvider from "./providers/AllLearnersProvider";

import ReportService from "./services/report.service";
import PlayerNew from "./common/PlayerNew";
import Demo from "./Demo";
import DemoPlayer from "./common/DemoPlayer";
import Scrom2004 from "./common/Scrom2004";

const App = () => {
  const dispatch = useDispatch();

  const appRef = useRef(null);

  const themeMode = useSelector((state) => state.themeReducer.themeMode);

  const userdata = useSelector((state) => state.userDataReducer);

  const [currentUser, setCurrentUser] = useState("");

  const [isCapturing, setIsCapturing] = useState(true);

  const [cartCourses, setCartCourses] = useState([]);

  useEffect(() => {
    const sessionTimeout = setTimeout(() => {
      setLoggedIn(false);
      clearLocStorage();
    }, 6 * 60 * 60 * 1000); // 6 hours in milliseconds
    return () => clearTimeout(sessionTimeout);
  }, []);

  const [loggedIn, setLoggedIn] = useState(false);

  const [onboarding, setOnboarding] = useState(false);

  const [roleid, setRoleid] = useState("");

  const [mode, setMode] = useState("0");

  const [menu, setMenu] = useState(false);

  let navigate = useNavigate();

  const [permissions, setPermissions] = useState([]);

  const setThemeSetting = () => {
    const user = AuthService.getCurrentUser();

    if (user) {
      SettingsService.getThemeSettings(user.user)
        .then((response) => {
          setMode(response.data[0].mode);
          if (response.data !== false) {
            setMode(response.data[0].mode);

            if (response.data[0].mode === "1") {
              setChangeMode("light");

              if (response.data[0].themeid === "1") {
                document.documentElement.style.setProperty(
                  "--primary-color",
                  "#58A6FF"
                );
                document.documentElement.style.setProperty(
                  "--secondary-color",
                  "#7EE787"
                );
                document.documentElement.style.setProperty(
                  "--nav-active",
                  "#58A6FF"
                );
              } else if (response.data[0].themeid === "2") {
                document.documentElement.style.setProperty(
                  "--primary-color",
                  "#8BC34A"
                );
                document.documentElement.style.setProperty(
                  "--secondary-color",
                  "#E2C5FF"
                );
                document.documentElement.style.setProperty(
                  "--nav-active",
                  "#57BE17"
                );
              } else if (response.data[0].themeid === "3") {
                document.documentElement.style.setProperty(
                  "--primary-color",
                  "#9D65F2"
                );
                document.documentElement.style.setProperty(
                  "--secondary-color",
                  "#A5D6FF"
                );
                document.documentElement.style.setProperty(
                  "--nav-active",
                  "#9D65F2"
                );
              } else if (response.data[0].themeid === "4") {
                document.documentElement.style.setProperty(
                  "--primary-color",
                  "#6767FF"
                );
                document.documentElement.style.setProperty(
                  "--secondary-color",
                  "#FFBEDD"
                );
                document.documentElement.style.setProperty(
                  "--nav-active",
                  "#DB1EA3"
                );
              } else if (response.data[0].themeid === "5") {
                document.documentElement.style.setProperty(
                  "--primary-color",
                  "#150E80"
                );
                document.documentElement.style.setProperty(
                  "--secondary-color",
                  "#FFBEDD"
                );
                document.documentElement.style.setProperty(
                  "--nav-active",
                  "#150E80"
                );
              }

              document.documentElement.style.setProperty(
                "--progress-icon",
                "#eef2f6"
              );

              document.documentElement.style.setProperty(
                "--chips-color",
                "#eef2f6"
              );
              document.documentElement.style.setProperty(
                "--body-dark-bg",
                "#ffffff"
              );
              document.documentElement.style.setProperty(
                "--dark-bg",
                "#eef2f6"
              );
              document.documentElement.style.setProperty(
                "--whiteColor",
                "#010001"
              );
              document.documentElement.style.setProperty(
                "--nav-inactive",
                "#7a7d80"
              );

              document.documentElement.style.setProperty(
                "--table-row",
                "#CCCDCF"
              );
              document.documentElement.style.setProperty(
                "--off-white",
                "#faf9f6"
              );
              document.documentElement.style.setProperty(
                "--table-row-even",
                "#f9f9f9"
              );
              document.documentElement.style.setProperty(
                "--event-thtd",
                "#f9f9f9"
              );
            } else {
              setChangeMode("dark");

              if (response.data[0].themeid === "1") {
                document.documentElement.style.setProperty(
                  "--primary-color",
                  "#24DFFE"
                );
                document.documentElement.style.setProperty(
                  "--secondary-color",
                  "#7EE787"
                );
                document.documentElement.style.setProperty(
                  "--nav-active",
                  "#24DFFE"
                );
              } else if (response.data[0].themeid === "2") {
                document.documentElement.style.setProperty(
                  "--primary-color",
                  "#87FE61"
                );
                document.documentElement.style.setProperty(
                  "--secondary-color",
                  "#7EE787"
                );
                document.documentElement.style.setProperty(
                  "--nav-active",
                  "#87FE61"
                );
              } else if (response.data[0].themeid === "3") {
                document.documentElement.style.setProperty(
                  "--primary-color",
                  "#9D65F2"
                );
                document.documentElement.style.setProperty(
                  "--secondary-color",
                  "#E2C5FF"
                );
                document.documentElement.style.setProperty(
                  "--nav-active",
                  "#9D65F2"
                );
              } else if (response.data[0].themeid === "4") {
                document.documentElement.style.setProperty(
                  "--primary-color",
                  "#0DAFD2"
                );
                document.documentElement.style.setProperty(
                  "--secondary-color",
                  "#A5D6FF"
                );
                document.documentElement.style.setProperty(
                  "--nav-active",
                  "#0DAFD2"
                );
              } else if (response.data[0].themeid === "5") {
                document.documentElement.style.setProperty(
                  "--primary-color",
                  "#FF4CCA"
                );
                document.documentElement.style.setProperty(
                  "--secondary-color",
                  "#FFBEDD"
                );
                document.documentElement.style.setProperty(
                  "--nav-active",
                  "#FF4CCA"
                );
              }

              document.documentElement.style.setProperty(
                "--progress-icon",
                "rgba(255, 255, 255, 0.3)"
              );

              document.documentElement.style.setProperty(
                "--chips-color",
                "#3A3A3A"
              );
              document.documentElement.style.setProperty(
                "--body-dark-bg",
                "#010001"
              );
              document.documentElement.style.setProperty(
                "--dark-bg",
                "#28282B"
              );
              document.documentElement.style.setProperty(
                "--whiteColor",
                "#f5f5f5"
              );
              document.documentElement.style.setProperty(
                "--nav-inactive",
                "#f5f5f5"
              );
              document.documentElement.style.setProperty(
                "--table-row",
                "#010001"
              );
              document.documentElement.style.setProperty(
                "--off-white",
                "#010101"
              );
              document.documentElement.style.setProperty(
                "--table-row-even",
                "#5c5c5e"
              );
              document.documentElement.style.setProperty(
                "--event-thtd",
                "#1E1D1D"
              );
            }
          } else {
            setMode(0);
          }
        })
        .catch((e) => console.log(e));
    }
  };

  useEffect(() => {
    const user = AuthService.getCurrentUser();

    // if (roleid === "4") {
    //   window.onfocus = function () {
    //     console.log("Window is focused!");
    //     setIsCapturing(false);

    //     setInterval(() => {
    //       window.location.reload();
    //     }, 5000);
    //   };
    // }

    if (user) {
      ReportService.getScromData()
        .then((resp) => {
          if (resp && resp.data && resp.data.error) {
            console.log("API Error:", resp.data.error);
          } else {
            if (resp.data !== false) {
              const apiData = resp.data;

              console.log(apiData);

              apiData.forEach((item) => {
                const dataKey = item.datakey;
                const dataValue = item.datavalue;
                localStorage.setItem(dataKey, dataValue);
              });
            }
          }
        })
        .catch((e) => console.log(e));
    }

    console.log(window.localStorage);

    dispatch(setUserData(user));

    if (user) {
      setRoleid(user.role_id);
      setCurrentUser(userdata);
      setLoggedIn(true);

      if (user.role_id !== "4") {
        PermissionService.getRolePermission(user.role_id, user.user)
          .then((res) => {
            setPermissions(res.data[0].permissions);
          })
          .catch((e) => console.log(e));
      }
    }

    const occsts = JSON.parse(localStorage.getItem("occsts"));

    if (occsts === "1") {
      setOnboarding(true);
    } else {
      setOnboarding(false);
    }

    EventBus.on("logout", () => {
      logOut();
    });

    return () => {
      EventBus.remove("logout");
    };
  }, []);

  useEffect(() => {
    changeTheme(themeMode);
  }, [themeMode]);

  const changeTheme = (mode) => {
    const user = AuthService.getCurrentUser();
    if (user) {
      SettingsService.getThemeSettings(user.user)
        .then((response) => {
          if (mode == 1) {
            setChangeMode("light");
            if (response.data[0].themeid === "1") {
              document.documentElement.style.setProperty(
                "--primary-color",
                "#58A6FF"
              );
              document.documentElement.style.setProperty(
                "--secondary-color",
                "#C9FCFC"
              );
              document.documentElement.style.setProperty(
                "--nav-active",
                "#58A6FF"
              );
            } else if (response.data[0].themeid === "2") {
              document.documentElement.style.setProperty(
                "--primary-color",
                "#8BC34A"
              );
              document.documentElement.style.setProperty(
                "--secondary-color",
                "#E2C5FF"
              );
              document.documentElement.style.setProperty(
                "--nav-active",
                "#57BE17"
              );
            } else if (response.data[0].themeid === "3") {
              document.documentElement.style.setProperty(
                "--primary-color",
                "#9D65F2"
              );
              document.documentElement.style.setProperty(
                "--secondary-color",
                "#A5D6FF"
              );
              document.documentElement.style.setProperty(
                "--nav-active",
                "#9D65F2"
              );
            } else if (response.data[0].themeid === "4") {
              document.documentElement.style.setProperty(
                "--primary-color",
                "#6767FF"
              );
              document.documentElement.style.setProperty(
                "--secondary-color",
                "#FFBEDD"
              );
              document.documentElement.style.setProperty(
                "--nav-active",
                "#DB1EA3"
              );
            } else if (response.data[0].themeid === "5") {
              document.documentElement.style.setProperty(
                "--primary-color",
                "#F6428B"
              );
              document.documentElement.style.setProperty(
                "--secondary-color",
                "#FFBEDD"
              );
              document.documentElement.style.setProperty(
                "--nav-active",
                "#150E80"
              );
            }

            document.documentElement.style.setProperty(
              "--progress-icon",
              "#eef2f6"
            );

            document.documentElement.style.setProperty(
              "--chips-color",
              "#eef2f6"
            );
            document.documentElement.style.setProperty(
              "--body-dark-bg",
              "#ffffff"
            );
            document.documentElement.style.setProperty("--dark-bg", "#eef2f6");
            document.documentElement.style.setProperty(
              "--whiteColor",
              "#010001"
            );
            document.documentElement.style.setProperty(
              "--nav-inactive",
              "#7a7d80"
            );

            document.documentElement.style.setProperty(
              "--table-row",
              "#CCCDCF"
            );
            document.documentElement.style.setProperty(
              "--off-white",
              "#faf9f6"
            );
            document.documentElement.style.setProperty(
              "--table-row-even",
              "#f9f9f9"
            );
            document.documentElement.style.setProperty(
              "--event-thtd",
              "#f9f9f9"
            );
          } else {
            setChangeMode("dark");
            if (response.data[0].themeid === "1") {
              document.documentElement.style.setProperty(
                "--primary-color",
                "#24DFFE"
              );
              document.documentElement.style.setProperty(
                "--secondary-color",
                "#C9FCFC"
              );
              document.documentElement.style.setProperty(
                "--nav-active",
                "#24DFFE"
              );
            } else if (response.data[0].themeid === "2") {
              document.documentElement.style.setProperty(
                "--primary-color",
                "#87FE61"
              );
              document.documentElement.style.setProperty(
                "--secondary-color",
                "#7EE787"
              );
              document.documentElement.style.setProperty(
                "--nav-active",
                "#87FE61"
              );
            } else if (response.data[0].themeid === "3") {
              document.documentElement.style.setProperty(
                "--primary-color",
                "#9D65F2"
              );
              document.documentElement.style.setProperty(
                "--secondary-color",
                "#E2C5FF"
              );
              document.documentElement.style.setProperty(
                "--nav-active",
                "#9D65F2"
              );
            } else if (response.data[0].themeid === "4") {
              document.documentElement.style.setProperty(
                "--primary-color",
                "#0DAFD2"
              );
              document.documentElement.style.setProperty(
                "--secondary-color",
                "#A5D6FF"
              );
              document.documentElement.style.setProperty(
                "--nav-active",
                "#0DAFD2"
              );
            } else if (response.data[0].themeid === "5") {
              document.documentElement.style.setProperty(
                "--primary-color",
                "#FF4CCA"
              );
              document.documentElement.style.setProperty(
                "--secondary-color",
                "#FFBEDD"
              );
              document.documentElement.style.setProperty(
                "--nav-active",
                "#FF4CCA"
              );
            }

            document.documentElement.style.setProperty(
              "--progress-icon",
              "rgba(255, 255, 255, 0.3)"
            );

            document.documentElement.style.setProperty(
              "--chips-color",
              "#3A3A3A"
            );
            document.documentElement.style.setProperty(
              "--body-dark-bg",
              "#010001"
            );
            document.documentElement.style.setProperty("--dark-bg", "#28282B");
            document.documentElement.style.setProperty(
              "--whiteColor",
              "#f5f5f5"
            );
            document.documentElement.style.setProperty(
              "--nav-inactive",
              "#f5f5f5"
            );
            document.documentElement.style.setProperty(
              "--table-row",
              "#010001"
            );
            document.documentElement.style.setProperty(
              "--off-white",
              "#010101"
            );
            document.documentElement.style.setProperty(
              "--table-row-even",
              "#5c5c5e"
            );
            document.documentElement.style.setProperty(
              "--event-thtd",
              "#1E1D1D"
            );
          }
        })
        .catch((e) => console.log(e));
    }
  };

  // const logOut = () => {
  //   AuthService.logout();
  //   setCurrentUser(undefined);
  //   setLoggedIn(false);
  //   navigate("/");
  //   localStorage.clear();
  // };

  const clearLocStorage = () => {
    AuthService.logout();
    setCurrentUser(undefined);
    setLoggedIn(false);
    navigate("/");
    localStorage.clear();
  };

  const logOut = async () => {
    try {
      const response = await UserService.logoutUsers();

      if (response.data === true) {
        await clearLocStorage();
      } else {
        console.error("User logout failed:", response.error);
      }
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };

  const handleState = () => {
    setOnboarding(true);
  };

  const [chnageMode, setChangeMode] = useState("dark");
  const [modeNo, setModeNo] = useState("0");

  const [bgColor, setBgColor] = useState(
    getComputedStyle(document.documentElement).getPropertyValue(
      "--body-dark-bg"
    )
  );

  const toggleMenu = () => {
    setMenu(!menu);
  };

  const fetchCartCourseData = (uid) => {
    const data = {
      usp: uid,
    };
    CartService.getCartCourses(data)
      .then((response) => {
        const newData = response.data;
        setCartCourses(newData);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    let user = AuthService.getCurrentUser();
    if (user) {
      fetchCartCourseData(user.user);
    }
  }, []);

  const handleContextMenu = (e) => {
    if (roleid === "4") {
      e.preventDefault();
    }
  };

  const handleKeyDown = (e) => {
    console.log(e.key);
    if (
      (e.ctrlKey && e.shiftKey && e.key === "I") ||
      (e.ctrlKey && e.key === "u")
    ) {
      e.preventDefault();
    }
  };

  return (
    <>
      <div
        className="App"
        tabIndex="0"
        // ref={appRef}
        // onContextMenu={handleContextMenu}
        // onKeyDown={handleKeyDown}
      >
        {isCapturing ? (
          <>
            {loggedIn ? (
              <>
                {onboarding ? (
                  <>
                    <div className="wrapper">
                      <div className="custom_container">
                        <div className={menu ? "main active" : "main"}>
                          <Topbar
                            logout={logOut}
                            fetchCartCourseData={fetchCartCourseData}
                            cartCourses={cartCourses}
                            toggleMenu={toggleMenu}
                          />
                          <div className="main__section">
                            <Routes>
                              {roleid === "1" || roleid === "2" ? (
                                <>
                                  {roleid == "1" ? (
                                    <>
                                      <Route
                                        exact
                                        path="/"
                                        element={<SADashboard />}
                                      />
                                      <Route
                                        exact
                                        path="/Dashboard"
                                        element={<SADashboard />}
                                      />
                                      <Route
                                        exact
                                        path="/ReconfigureLMS"
                                        element={<Steps />}
                                      />
                                      <Route
                                        exact
                                        path="/SystemManagers"
                                        element={<SystemManagers />}
                                      />
                                      <Route
                                        exact
                                        path="/PresetRoles"
                                        element={<PresetRoles />}
                                      />
                                      <Route
                                        exact
                                        path="/RaiseRequest"
                                        element={<RaiseRequest />}
                                      />
                                    </>
                                  ) : roleid == "2" ? (
                                    <>
                                      <Route
                                        exact
                                        path="/"
                                        element={
                                          <AdminDashbaord roleid={roleid} />
                                        }
                                      />
                                      <Route
                                        exact
                                        path="/Dashboard"
                                        element={
                                          <AdminDashbaord roleid={roleid} />
                                        }
                                      />
                                      <Route
                                        exact
                                        path="/ReconfigureLMS"
                                        element={<AdminOnboard />}
                                      />
                                      <Route
                                        exact
                                        path="/RaiseRequest"
                                        element={<RaiseRequest />}
                                      />
                                      <Route
                                        exact
                                        path="/Calendar"
                                        element={<Event />}
                                      />
                                    </>
                                  ) : (
                                    <></>
                                  )}

                                  <Route
                                    exact
                                    path="/AllLearners"
                                    element={
                                      <AllLearnersProvider>
                                        <AllLearners
                                          permissions={permissions}
                                          roleid={roleid}
                                        />
                                      </AllLearnersProvider>
                                    }
                                  />
                                  <Route
                                    exact
                                    path="/Cohorts"
                                    element={<Cohorts roleid={roleid} />}
                                  />
                                  <Route
                                    exact
                                    path="/AddCohort"
                                    element={<AddCohort />}
                                  />
                                  <Route
                                    exact
                                    path="/EditCohort"
                                    element={<EditCohort />}
                                  />
                                  <Route
                                    exact
                                    path="/AddUser"
                                    element={<AddUser />}
                                  />
                                  <Route
                                    exact
                                    path="/EditLearner"
                                    element={<EditLearner />}
                                  />
                                  {/* <Route
                              exact
                              path="/ViewStudentCourses"
                              element={<ViewStudentCourses />}
                            /> */}
                                  <Route
                                    exact
                                    path="/EditSystemManagers"
                                    element={<EditSystemManagers />}
                                  />
                                  <Route
                                    exact
                                    path="/ManageCohortParticipants"
                                    element={<ManageCohortParticipants />}
                                  />
                                  <Route
                                    exact
                                    path="/ManageCourses"
                                    element={<ManageCourses roleid={roleid} />}
                                  />
                                  <Route
                                    exact
                                    path="/EditCourse"
                                    element={<EditCourse />}
                                  />
                                  <Route
                                    exact
                                    path="/ManageParticipants"
                                    element={<ManageParticipants />}
                                  />
                                  <Route
                                    exact
                                    path="/ManagePathway"
                                    element={<ManagePathway roleid={roleid} />}
                                  />
                                  <Route
                                    exact
                                    path="/PathwayParticipants"
                                    element={<PathwayParticipants />}
                                  />
                                  <Route
                                    exact
                                    path="/CreatePathway"
                                    element={<CreatePathway />}
                                  />
                                  <Route
                                    exact
                                    path="/EditPathway"
                                    element={<EditPathway />}
                                  />
                                  <Route
                                    exact
                                    path="/ManageOrganisation"
                                    element={
                                      <ManageOrganisation roleid={roleid} />
                                    }
                                  />
                                  <Route
                                    exact
                                    path="/AddSubOrg"
                                    element={<AddSubOrg />}
                                  />
                                  <Route
                                    exact
                                    path="/EditSubOrg"
                                    element={<EditSubOrg />}
                                  />
                                  <Route
                                    exact
                                    path="/EditOrg"
                                    element={<EditOrg />}
                                  />
                                  <Route
                                    exact
                                    path="/CertificateConfigure"
                                    element={<CertificateConfigure />}
                                  />
                                  <Route
                                    exact
                                    path="/SetCertificateTemplate"
                                    element={<SetCertificateTemplate />}
                                  />
                                  <Route
                                    exact
                                    path="/ServerSettings"
                                    element={<ServerSettings />}
                                  />
                                  <Route
                                    exact
                                    path="/ThemeSettings"
                                    element={
                                      <ThemeSettings chnageMode={chnageMode} />
                                    }
                                  />
                                  <Route
                                    exact
                                    path="/HelpNSupport"
                                    element={<ErrorRequest />}
                                  />
                                  <Route
                                    exact
                                    path="/CertificatePreview"
                                    element={<CertificatePreview />}
                                  />
                                  <Route
                                    exact
                                    path="/CreateCourse"
                                    element={<CreateCourse />}
                                  />
                                  <Route
                                    exact
                                    path="/CourseDraft"
                                    element={<CourseDraft />}
                                  />
                                  <Route
                                    exact
                                    path="/Reports"
                                    element={<Reports />}
                                  />
                                  <Route
                                    exact
                                    path="/ViewReport"
                                    element={<ViewReport />}
                                  />
                                  <Route
                                    exact
                                    path="/ActivityLog"
                                    element={<ActivityLog />}
                                  />
                                  <Route
                                    exact
                                    path="/ActivityLogNew"
                                    element={<ActivityLogNew />}
                                  />

                                  <Route
                                    exact
                                    path="/CertificateReports"
                                    element={<CertificateReports />}
                                  />

                                  <Route
                                    exact
                                    path="/CourseEnrollment"
                                    element={<CourseEnrollmentReport />}
                                  />

                                  <Route
                                    exact
                                    path="/UserEnrollment"
                                    element={<UserEnrollmentReport />}
                                  />

                                  <Route
                                    exact
                                    path="/ActivityResult"
                                    element={<ActivityResultReport />}
                                  />
                                  <Route
                                    exact
                                    path="/PaymentHistory"
                                    element={<PaymentHistoryReport />}
                                  />
                                  <Route
                                    exact
                                    path="/FeedbackReport"
                                    element={<FeedbackReport />}
                                  />
                                  <Route
                                    exact
                                    path="/CourseStatusReport"
                                    element={<CourseStatusReport />}
                                  />

                                  <Route
                                    exact
                                    path="/AddReport"
                                    element={<AddReport />}
                                  />
                                  <Route
                                    exact
                                    path="/EditReport"
                                    element={<EditReport />}
                                  />
                                  <Route
                                    exact
                                    path="/Course"
                                    element={<ViewCourse />}
                                  />
                                  <Route
                                    exact
                                    path="/Activity"
                                    element={<ViewActivity />}
                                  />
                                  <Route
                                    exact
                                    path="/Player"
                                    element={<Player />}
                                  />
                                  
                                  {/* <Route exact path="/PlayerNew/:aid/:cid/:uid" element={<PlayerNew />} /> */}

                                  <Route
                                    exact
                                    path="/Certificate"
                                    element={<Certificate />}
                                  />
                                  <Route
                                    exact
                                    path="/EditProfile"
                                    element={<EditProfile />}
                                  />
                                </>
                              ) : roleid === "4" ? (
                                <>
                                  <Route
                                    exact
                                    path="/TransactionHistory"
                                    element={<PaymentHistoryReport />}
                                  />
                                  <Route
                                    exact
                                    path="/Success"
                                    element={<PaymentSuccess />}
                                  />
                                  <Route
                                    exact
                                    path="/Failure"
                                    element={<PaymentFailure />}
                                  />
                                  <Route
                                    exact
                                    path="/HelpNSupport"
                                    element={<ErrorRequest />}
                                  />
                                  <Route
                                    exact
                                    path="/"
                                    element={
                                      <LearnerDashboard
                                        mode={mode}
                                        chnageMode={chnageMode}
                                      />
                                    }
                                  />
                                  <Route
                                    exact
                                    path="/Dashboard"
                                    element={
                                      <LearnerDashboard
                                        mode={mode}
                                        chnageMode={chnageMode}
                                      />
                                    }
                                  />
                                  <Route
                                    exact
                                    path="/Learn"
                                    element={<Learn />}
                                  />
                                  <Route
                                    exact
                                    path="/Course"
                                    element={<ViewCourse />}
                                  />
                                  <Route
                                    exact
                                    path="/Activity"
                                    element={<ViewActivity />}
                                  />
                                  <Route
                                    exact
                                    path="/Player"
                                    element={<Player />}
                                  />
                                  
                                  {/* <Route exact path="/PlayerNew/:aid/:cid/:uid" element={<PlayerNew />} /> */}

                                  <Route
                                    exact
                                    path="/Track"
                                    element={<Track />}
                                  />
                                  <Route
                                    exact
                                    path="/Achievements"
                                    element={<Achievements />}
                                  />
                                  {/* <Route
                              exact
                              path="/Communication"
                              element={<Communication />}
                            /> */}
                                  <Route
                                    exact
                                    path="/Certificate"
                                    element={<Certificate />}
                                  />
                                  <Route
                                    exact
                                    path="/RaiseRequest"
                                    element={<RaiseRequest />}
                                  />
                                  <Route
                                    exact
                                    path="/Player"
                                    element={<Player />}
                                  />
                                  
                                  {/* <Route exact path="/PlayerNew/:aid/:cid/:uid" element={<PlayerNew />} /> */}

                                  <Route
                                    exact
                                    path="/EditProfile"
                                    element={<EditProfile />}
                                  />
                                  <Route
                                    exact
                                    path="/ReconfigureLMS"
                                    element={<LearnerOnboarding />}
                                  />
                                  <Route
                                    exact
                                    path="/Catalog"
                                    element={<CourseCatalog />}
                                  />
                                  <Route
                                    exact
                                    path="/CatalogCourse"
                                    element={
                                      <CatalogCourseOverview
                                        fetchCartCourseData={
                                          fetchCartCourseData
                                        }
                                      />
                                    }
                                  />
                                </>
                              ) : (
                                <>
                                  <Route
                                    exact
                                    path="/"
                                    element={
                                      <AdminDashbaord
                                        permissions={permissions}
                                      />
                                    }
                                  />
                                  <Route
                                    exact
                                    path="/Dashboard"
                                    element={
                                      <AdminDashbaord
                                        permissions={permissions}
                                      />
                                    }
                                  />

                                  {/* learners permission */}

                                  {permissions &&
                                  permissions.includes("ml1") ? (
                                    <>
                                      <Route
                                        exact
                                        path="/AllLearners"
                                        element={
                                          <AllLearnersProvider>
                                            <AllLearners
                                              permissions={permissions}
                                            />
                                          </AllLearnersProvider>
                                        }
                                      />
                                    </>
                                  ) : (
                                    <></>
                                  )}

                                  {permissions &&
                                  permissions.includes("ml2") ? (
                                    <>
                                      <Route
                                        exact
                                        path="/AddUser"
                                        element={<AddUser />}
                                      />
                                    </>
                                  ) : (
                                    <></>
                                  )}

                                  {permissions &&
                                  permissions.includes("ml3") ? (
                                    <>
                                      <Route
                                        exact
                                        path="/EditLearner"
                                        element={<EditLearner />}
                                      />
                                    </>
                                  ) : (
                                    <></>
                                  )}

                                  {/* learners permission */}

                                  {/* cohorts permission */}

                                  {permissions &&
                                  permissions.includes("mgc1") ? (
                                    <>
                                      <Route
                                        exact
                                        path="/Cohorts"
                                        element={
                                          <Cohorts permissions={permissions} />
                                        }
                                      />
                                    </>
                                  ) : (
                                    <></>
                                  )}

                                  {permissions &&
                                  permissions.includes("mgc2") ? (
                                    <>
                                      <Route
                                        exact
                                        path="/AddCohort"
                                        element={<AddCohort />}
                                      />
                                    </>
                                  ) : (
                                    <></>
                                  )}

                                  {permissions &&
                                  permissions.includes("mgc3") ? (
                                    <>
                                      <Route
                                        exact
                                        path="/EditCohort"
                                        element={<EditCohort />}
                                      />
                                    </>
                                  ) : (
                                    <></>
                                  )}

                                  {permissions &&
                                  permissions.includes("mgc5") ? (
                                    <>
                                      <Route
                                        exact
                                        path="/ManageCohortParticipants"
                                        element={<ManageCohortParticipants />}
                                      />
                                    </>
                                  ) : (
                                    <></>
                                  )}

                                  {/* cohorts permission */}

                                  {/* courses permission */}

                                  {permissions &&
                                  permissions.includes("mc1") ? (
                                    <>
                                      <Route
                                        exact
                                        path="/ManageCourses"
                                        element={
                                          <ManageCourses
                                            permissions={permissions}
                                          />
                                        }
                                      />
                                    </>
                                  ) : (
                                    <></>
                                  )}

                                  {permissions &&
                                  permissions.includes("mc2") ? (
                                    <>
                                      <Route
                                        exact
                                        path="/CreateCourse"
                                        element={<CreateCourse />}
                                      />
                                      <Route
                                        exact
                                        path="/CourseDraft"
                                        element={<CourseDraft />}
                                      />
                                    </>
                                  ) : (
                                    <></>
                                  )}

                                  {permissions &&
                                  permissions.includes("mc3") ? (
                                    <>
                                      <Route
                                        exact
                                        path="/EditCourse"
                                        element={<EditCourse />}
                                      />
                                    </>
                                  ) : (
                                    <></>
                                  )}

                                  {permissions &&
                                  permissions.includes("mc5") ? (
                                    <>
                                      <Route
                                        exact
                                        path="/ManageParticipants"
                                        element={<ManageParticipants />}
                                      />
                                    </>
                                  ) : (
                                    <></>
                                  )}

                                  {/* courses permission */}

                                  {/* pathway permission */}

                                  {permissions &&
                                  permissions.includes("mp1") ? (
                                    <>
                                      <Route
                                        exact
                                        path="/ManagePathway"
                                        element={
                                          <ManagePathway
                                            permissions={permissions}
                                          />
                                        }
                                      />
                                    </>
                                  ) : (
                                    <></>
                                  )}

                                  {permissions &&
                                  permissions.includes("mp2") ? (
                                    <>
                                      <Route
                                        exact
                                        path="/CreatePathway"
                                        element={<CreatePathway />}
                                      />
                                    </>
                                  ) : (
                                    <></>
                                  )}

                                  {permissions &&
                                  permissions.includes("mp3") ? (
                                    <>
                                      <Route
                                        exact
                                        path="/EditPathway"
                                        element={<EditPathway />}
                                      />
                                    </>
                                  ) : (
                                    <></>
                                  )}

                                  {permissions &&
                                  permissions.includes("mp5") ? (
                                    <>
                                      <Route
                                        exact
                                        path="/PathwayParticipants"
                                        element={<PathwayParticipants />}
                                      />
                                    </>
                                  ) : (
                                    <></>
                                  )}

                                  {/* pathway permission */}

                                  {/* org/sub-org permission */}

                                  {permissions &&
                                  permissions.includes("mo1") ? (
                                    <>
                                      <Route
                                        exact
                                        path="/ManageOrganisation"
                                        element={
                                          <ManageOrganisation
                                            permissions={permissions}
                                          />
                                        }
                                      />
                                    </>
                                  ) : (
                                    <></>
                                  )}

                                  {permissions &&
                                  permissions.includes("mo2") ? (
                                    <>
                                      <Route
                                        exact
                                        path="/AddSubOrg"
                                        element={<AddSubOrg />}
                                      />
                                    </>
                                  ) : (
                                    <></>
                                  )}

                                  {permissions &&
                                  permissions.includes("mo3") ? (
                                    <>
                                      <Route
                                        exact
                                        path="/EditSubOrg"
                                        element={<EditSubOrg />}
                                      />
                                      <Route
                                        exact
                                        path="/EditOrg"
                                        element={<EditOrg />}
                                      />
                                    </>
                                  ) : (
                                    <></>
                                  )}

                                  {/* org/sub-org permission */}

                                  <Route
                                    exact
                                    path="/CertificateConfigure"
                                    element={<CertificateConfigure />}
                                  />
                                  <Route
                                    exact
                                    path="/SetCertificateTemplate"
                                    element={<SetCertificateTemplate />}
                                  />
                                  <Route
                                    exact
                                    path="/ServerSettings"
                                    element={<ServerSettings />}
                                  />
                                  <Route
                                    exact
                                    path="/ThemeSettings"
                                    element={
                                      <ThemeSettings chnageMode={chnageMode} />
                                    }
                                  />
                                  <Route
                                    exact
                                    path="/HelpNSupport"
                                    element={<ErrorRequest />}
                                  />
                                  <Route
                                    exact
                                    path="/CertificatePreview"
                                    element={<CertificatePreview />}
                                  />

                                  <Route
                                    exact
                                    path="/Reports"
                                    element={<Reports />}
                                  />
                                  <Route
                                    exact
                                    path="/ViewReport"
                                    element={<ViewReport />}
                                  />
                                  <Route
                                    exact
                                    path="/ActivityLog"
                                    element={<ActivityLog />}
                                  />
                                  <Route
                                    exact
                                    path="/ActivityLogNew"
                                    element={<ActivityLogNew />}
                                  />

                                  <Route
                                    exact
                                    path="/CertificateReports"
                                    element={<CertificateReports />}
                                  />
                                  <Route
                                    exact
                                    path="/CourseEnrollment"
                                    element={<CourseEnrollmentReport />}
                                  />
                                  <Route
                                    exact
                                    path="/UserEnrollment"
                                    element={<UserEnrollmentReport />}
                                  />
                                  <Route
                                    exact
                                    path="/ActivityResult"
                                    element={<ActivityResultReport />}
                                  />
                                  <Route
                                    exact
                                    path="/PaymentHistory"
                                    element={<PaymentHistoryReport />}
                                  />
                                  <Route
                                    exact
                                    path="/CourseStatusReport"
                                    element={<CourseStatusReport />}
                                  />

                                  <Route
                                    exact
                                    path="/FeedbackReport"
                                    element={<FeedbackReport />}
                                  />
                                  <Route
                                    exact
                                    path="/AddReport"
                                    element={<AddReport />}
                                  />
                                  <Route
                                    exact
                                    path="/EditReport"
                                    element={<EditReport />}
                                  />
                                  <Route
                                    exact
                                    path="/Course"
                                    element={<ViewCourse />}
                                  />
                                  <Route
                                    exact
                                    path="/Activity"
                                    element={<ViewActivity />}
                                  />
                                  <Route
                                    exact
                                    path="/Player"
                                    element={<Player />}
                                  />
                                  
                                  {/* <Route exact path="/PlayerNew/:aid/:cid/:uid" element={<PlayerNew />} /> */}

                                  <Route
                                    exact
                                    path="/Certificate"
                                    element={<Certificate />}
                                  />
                                </>
                              )}
                              <Route
                                exact
                                path="/Calendar"
                                element={<Event />}
                              />

                              <Route
                                exact
                                path="/ThemeSettings"
                                element={<ThemeSettings />}
                              />
                              <Route path="*" element={<PageNotFound />} />
                              <Route exact path="/demoPlayer" element={<DemoPlayer />} />
                              <Route exact path="/Scrom2004" element={<Scrom2004 />} />
                              
                            </Routes>
                          </div>
                        </div>
                      </div>
                    </div>
                    <GoogleTranslate />
                    <Sidebar
                      logout={logOut}
                      menu={menu}
                      permissions={permissions}
                    />
                  </>
                ) : (
                  <>
                    <div className="wrapper onboarding">
                      <div className="custom_container">
                        <div className="main">
                          <Topbar logout={logOut} toggleMenu={toggleMenu} />
                          <div className="main__section">
                            <Routes>
                              {roleid === "1" ? (
                                <>
                                  <Route exact path="/" element={<Steps />} />
                                  <Route
                                    exact
                                    path="/Onboard"
                                    element={<Steps />}
                                  />
                                </>
                              ) : roleid === "2" || roleid === "3" ? (
                                <>
                                  <Route
                                    exact
                                    path="/"
                                    element={<AdminOnboard />}
                                  />
                                  <Route
                                    exact
                                    path="/Onboard"
                                    element={<AdminOnboard />}
                                  />
                                </>
                              ) : roleid === "4" ? (
                                <>
                                  <Route
                                    exact
                                    path="/"
                                    element={<LearnerOnboarding />}
                                  />
                                  <Route
                                    exact
                                    path="/Onboard"
                                    element={<LearnerOnboarding />}
                                  />
                                </>
                              ) : (
                                <></>
                              )}
                              
                            </Routes>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </>
            ) : (
              <>
                <Routes>
                  <Route exact path="*" element={<Login />} />
                  <Route exact path="/" element={<Login />} />
                  <Route exact path="/ForgotPassword" element={<Forgot />} />
                  <Route
                    exact
                    path="/ChangePassword/"
                    element={<ChangePassword />}
                  />
                  <Route exact path="/Dashboard" element={<Login />} />
                  <Route
                    path="/CourseCatalog"
                    element={
                      <>
                        <div className="wrapper">
                          <div className="custom_container">
                            <div className="main">
                              <Topbar logout={logOut} toggleMenu={toggleMenu} />
                              <div className="main__section">
                                <GuestCourseCatalog />
                              </div>
                            </div>
                          </div>
                        </div>
                        <Sidebar
                          logout={logOut}
                          menu={menu}
                          permissions={permissions}
                        />
                      </>
                    }
                  />
                  <Route
                    path="/CourseOverview"
                    element={
                      <>
                        <div className="wrapper">
                          <div className="custom_container">
                            <div className="main">
                              <Topbar logout={logOut} toggleMenu={toggleMenu} />
                              <div className="main__section">
                                <GuestCatalogCourseOverview />
                              </div>
                            </div>
                          </div>
                        </div>
                        <Sidebar
                          logout={logOut}
                          menu={menu}
                          permissions={permissions}
                        />
                      </>
                    }
                  />
                  
                  <Route exact path="/demo" element={<Demo />} />
                  
                  <Route exact path="/PlayerNew/:userData" element={<PlayerNew />} />

                  
                 
                </Routes>
              </>
            )}
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default App;
